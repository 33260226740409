import React, { useCallback, useState } from 'react'
import { FcMoneyTransfer } from 'react-icons/fc'
import { useRemoveCoupon } from '../hooks'

interface IProps {
  code: string
}

const CouponCodePill: React.FC<IProps> = ({ code }) => {
  const [state, setState] = useState('ready')
  const removeCoupon = useRemoveCoupon()
  const handleRemove = useCallback(async () => {
    setState('loading')
    try {
      await removeCoupon(code)
    } catch (e) {
      alert(e)
    } finally {
      setState('ready')
    }
  }, [code, removeCoupon])
  return (
    <span className="uppercase inline-flex rounded-full items-center py-1 pl-3 pr-2 text-sm font-medium bg-neutral-100 text-neutral-700">
      <div className={'flex space-x-1 items-center'}>
        <FcMoneyTransfer />
        <div>{code}</div>
      </div>
      <button
        type="button"
        onClick={handleRemove}
        disabled={state === 'loading'}
        className="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-neutral-400 hover:bg-neutral-200 hover:text-neutral-500 focus:outline-none focus:bg-neutral-500 focus:text-white"
      >
        <span className="sr-only">Remove large option</span>
        <svg
          className="h-2 w-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  )
}

export default CouponCodePill
