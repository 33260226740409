import React, { useEffect } from 'react'
import classNames from 'classnames'
import Head from 'next/head'

import { useStore } from '@/stores'
import { CartSlider } from '@/features/cart/components'

import Header from '../Header'

import Footer from '../Footer'
import MainMenuDialog from '../MainMenuDialog'

// TODO: dynamically load some components (i.e. modals?) example from old common/Layout/Layout.tsx
/*
const Loading = () => (
  <div className="flex items-center justify-center p-3 text-center w-80 h-80">
    Loading...
  </div>
)

const dynamicProps = {
  loading: () => <Loading />,
}
*/

interface IProps {
  // TODO: Pass in CMS pageprops here (to update links, header, cart lang, and other stuff)
  // pageProps: {
  //  pages?: Page[]
  //  categories: Category[]
  //}
  mutedBackground?: boolean
  children: React.ReactNode
  showLiveChatWidget?: boolean
  pageType?: 'category' | 'page' | 'product' | 'default'
  pageMeta?: {
    resourceId?: string
    resourceTitle?: string
  }
}

const Layout: React.FC<IProps> = ({
  children,
  mutedBackground,
  showLiveChatWidget,
  pageType = 'page',
  pageMeta,
}) => {
  const store = useStore()
  useEffect(() => {
    if (pageType) {
      document.body.setAttribute('data-page-type', pageType)
    }
  }, [pageType])

  return (
    <>
      <Head>
        {showLiveChatWidget && (
          <style>
            {`
            iframe#kustomer-ui-sdk-iframe {
              display: block !important;
            }
          `}
          </style>
        )}
      </Head>
      <div className={'overflow-x-hidden'}>
        <header>
          <Header />
        </header>
        <main className={classNames({ 'bg-gray-50': mutedBackground })}>
          {children}
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
      <CartSlider open={store.isCartOpen} onClose={store.closeCart} />
      <MainMenuDialog
        open={store.isMainMenuOpen}
        onClose={store.closeMainMenu}
      />
    </>
  )
}

export default Layout
