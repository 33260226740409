import axios from 'axios'
import { useCallback } from 'react'
import useCart from './use-cart'

const useRemoveItem = () => {
  const { mutate } = useCart()
  const removeItem = useCallback(
    async (item_id: string) => {
      const { data: cart } = await axios.delete(`/api/cart/items/${item_id}`)
      return mutate(cart)
    },
    [mutate]
  )
  return removeItem
}
export default useRemoveItem
