import { getKustomerJwt } from '@/features/auth/api/client'
import { captureException } from '@sentry/nextjs'

const login = async () => {
  const Kustomer = (window as any).Kustomer
  try {
    const {
      data: { jwtToken },
    } = await getKustomerJwt()

    Kustomer.login({ jwtToken }, (response: any, error: any) => {
      if (error) {
        captureException(error)
        console.log('Kustomer login error', error)
        return
      }
      // Cache token in sessionStorage
      sessionStorage.setItem('kustomerToken', jwtToken)
      console.log('Kustomer login success', response)
    })
  } catch (e) {
    captureException(e)
    console.error(e)
  }
}

export default login
