import axios from 'axios'
import { useCallback } from 'react'
import useCart from './use-cart'

const useAddCoupon = () => {
  const { mutate } = useCart()

  const addCoupon = useCallback(
    async (code: string) => {
      return mutate(async () => {
        const { data: cart } = await axios.post('/api/cart/coupons', {
          code,
        })
        return cart
      })
    },
    [mutate]
  )

  return { addCoupon }
}
export default useAddCoupon
