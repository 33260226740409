const localizePrice = (price: number, currency: string = 'en-US') => {
  if (!price) {
    return '$0.00'
  }
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

export default localizePrice
