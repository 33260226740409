import React from 'react'

interface IProps {
  children: React.ReactNode
}

const AlertBanner: React.FC<IProps> = ({ children }) => {
  return (
    <div
      className={
        'bg-primary font-sans-condensed font-bold text-xxs uppercase text-white text-center p-1'
      }
    >
      {children}
    </div>
  )
}

export default AlertBanner
