import * as Sentry from '@sentry/nextjs'
import { AxiosError } from 'axios'
import { getSmileIoJwt } from '@/features/auth/api/client'

const initialize = async (skipLogin?: boolean) => {
  const SmileUI = (window as any).SmileUI
  let customer_identity_jwt = null
  try {
    if (skipLogin) {
      return
    }
    const {
      data: { jwtToken },
    } = await getSmileIoJwt()
    customer_identity_jwt = jwtToken
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e?.response?.status === 401) {
        return
      }
    }
    Sentry.captureException(e)
  } finally {
    SmileUI.init({
      channel_key: 'channel_Qude0iXfB7MKHMNOeafbYGCh',
      customer_identity_jwt,
    })
  }
}

export default initialize
