import React from 'react'
import { AlertBanner } from '@/components'
import i18n from '@/config/i18n.json'

// TODO: Fix all breakpoints (the T gets cut off on some breakpoints)
const SoldOutAlertBanner: React.FC = () => {
  return (
    <AlertBanner>
      <a
        className={'select-auto cursor-pointer uppercase'}
        role={'button'}
        href={i18n.en.other.textLindaSmsLink}
      >
        <div className={'max-w-sm mx-auto p-1.5 sm:p-1'}>
          <svg viewBox="0 0 253 30">
            <text x="0" y="25">
              {/** TODO: Set leading to 0.84rem */}
              <tspan className={'text-3xl'}>Sold the fuck out</tspan>
            </text>
          </svg>
          <svg viewBox="0 0 233 30">
            <text className={'text-sm font-light fill-white'}>
              {/** TODO: Set lineHeight to 0.95rem */}
              <tspan x={'5'} y="13">
                CLICK HERE TO GET ON OUR TEXT LIST
              </tspan>
              <tspan x="5" y="28">
                &amp; GET NOTIFIED WHEN IT&apos;S RESTOCKED
              </tspan>
            </text>
          </svg>
        </div>
      </a>
    </AlertBanner>
  )
}
export default SoldOutAlertBanner
