import React from 'react'
import type { Image } from '@framework/schema'
import classNames from 'classnames'
import Link from 'next/link'
import { Builder, withChildren } from '@builder.io/react'
import CategoryListItem from './CategoryListItem'

export type Category = {
  id: string
  slug: string
  image?: Image
}

interface IProps {
  categories: any[]
  type?: 'index' | 'pageSection'
}

const CategoryList: React.FC<IProps> = ({
  categories,
  type = 'pageSection',
}) => {
  return (
    <div
      className={classNames('grid gap-1', {
        'sm:grid-cols-2': type === 'pageSection',
        'grid-cols-2 md:grid-cols-3': type === 'index',
      })}
    >
      {categories?.map(({ id, image, slug }) => (
        <Link
          key={id}
          prefetch={false}
          href={{ pathname: '/categories/[slug]', query: { slug } }}
        >
          <a>
            <CategoryListItem image={image} />
          </a>
        </Link>
      ))}
    </div>
  )
}

Builder.registerComponent(withChildren(CategoryList), {
  name: 'CategoryList',
  inputs: [
    {
      name: 'slugs',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'slug',
          type: 'string', // TODO: Integrate with builder.io bigcommerce category selection feature
        },
      ],
    },
  ],
})

export default CategoryList
