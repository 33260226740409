import classNames from 'classnames'
import React, { useMemo } from 'react'
import settings from '@/config/settings.json'
import { localizePrice } from '@/features/products/utils'

interface IProps {
  cartAmount?: number
}
// TODO: Pull from BigCommerce backend
const FreeShippingMessageBox: React.FC<IProps> = ({ cartAmount }) => {
  const eligibleForFreeShipping = useMemo(() => {
    if (!cartAmount) {
      return false
    }
    return cartAmount >= settings.freeShippingThreshold
  }, [cartAmount])

  const amountUntilFreeShipping = useMemo(() => {
    if (!cartAmount) {
      return settings.freeShippingThreshold
    }
    return settings.freeShippingThreshold - cartAmount
  }, [cartAmount])
  return (
    <div
      className={classNames(
        'my-4 border-2 border-dotted p-3 font-medium text-sm text-center tracking-wide',
        {
          'bg-red-50 border-primary': !eligibleForFreeShipping,
          'bg-green-50 border-green-500': eligibleForFreeShipping,
        }
      )}
    >
      {eligibleForFreeShipping ? (
        <span>
          CONGRATS, YOU QUALIFY FOR FREE SHIPPING ASSUMING YOU ARE IN THE USA.
        </span>
      ) : (
        <span>
          HEY STUPID, SPEND {localizePrice(amountUntilFreeShipping)} MORE AND
          YOU GET FREE SHIPPING ASSUMING YOU ARE IN THE USA.
        </span>
      )}
    </div>
  )
}

export default FreeShippingMessageBox
