import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'

interface IProps {
  maxWidth: 'md' | 'xl'
  title?: string
  open?: boolean
  onClose: () => void
  children: React.ReactNode
}

const Drawer: React.FC<IProps> = ({
  open,
  onClose,
  children,
  maxWidth = 'md',
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as={'div'}
        className={'fixed inset-0 overflow-hidden z-10'}
        onClose={onClose}
      >
        <div className={'absolute inset-0 overflow-hidden'}>
          <div className={'absolute inset-0'} onClick={onClose} />
          <div className={'fixed inset-y-0 right-0 pl-10 max-w-full flex'}>
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={classNames('w-screen', {
                  'max-w-md': maxWidth === 'md',
                  'max-w-xl': maxWidth === 'xl',
                })}
              >
                <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Drawer
