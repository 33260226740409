import { Builder, withChildren } from '@builder.io/react'

import React from 'react'
import Link from 'next/link'

import { Category, Query } from '@/types/bigcommerce/generated/graphql'
import { ProductTileSet, ProductTile } from '@/features/products/components'

// import { useCategory } from '../../api/get-category'

interface IProps {
  categorySlug: string
  productCount?: number
  withHeader?: boolean
  withFooter?: boolean
  data?: Query
}

const CategoryProductTileSet: React.FC<IProps> = ({
  categorySlug,
  productCount = 4,
  withHeader,
  withFooter,
  data,
  ...props
}) => {
  // console.log(props)
  const category = data?.site?.route.node as Category
  // const data = data?.site.products //|| useCategory(categorySlug, productCount)
  // TODO: useCategory if data not passed in through props
  // TODO: https://web.dev/dom-size/?utm_source=lighthouse&utm_medium=devtools
  // https://www.npmjs.com/package/@builder.io/utils/v/1.0.3
  /* const { loading, data, error } = useCategory({
    path: '/' + categorySlug,
  })*/

  return (
    <>
      <ProductTileSet
        heading={withHeader && category ? category.name : undefined}
      >
        {category?.products.edges?.map((product) => (
          <ProductTile key={product!.node.entityId} product={product!.node} />
        ))}
      </ProductTileSet>
      {withFooter && (
        <Link href={`/categories/${categorySlug}`} prefetch={false}>
          <a className="text-center mx-auto button block my-4 button--large">
            View Collection
          </a>
        </Link>
      )}
    </>
  )
}

Builder.registerComponent(withChildren(CategoryProductTileSet), {
  name: 'CategoryProductTileSet',
  inputs: [
    {
      name: 'categorySlug',
      type: 'string', // TODO: Integrate with builder.io bigcommerce category selection feature
      friendlyName: 'Category Slug',
      defaultValue: 'FEATURED',
    },
    {
      name: 'productCount',
      type: 'number',
      friendlyName: 'Product Count',
      defaultValue: 4,
    },
    {
      name: 'withHeader',
      type: 'boolean',
      friendlyName: 'Show Header',
      defaultValue: true,
    },
    {
      name: 'withFooter',
      type: 'boolean',
      friendlyName: 'Show Footer',
      defaultValue: true,
    },
  ],
})

export default CategoryProductTileSet
