import { getSession } from 'next-auth/react'
import jwt from 'jsonwebtoken'

import { login, logout } from '.'

const handleKustomerInitialized = async () => {
  console.log('handleKustomerInitialized')
  const token = sessionStorage.getItem('kustomerToken')
  const session = await getSession()

  // Guard: User is not logged in
  if (!session) {
    if (token) {
      sessionStorage.removeItem('kustomerToken')
    }

    logout()
    return
  }

  // Guard: Customer is logged in with valid cached token
  if (token && jwt.decode(token)) {
    console.log('Valid token, skipping get token process.')
    return
  }

  login()
}

export default handleKustomerInitialized
