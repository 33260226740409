import Link from 'next/link'
import React, { useCallback } from 'react'
import i18n from '@/config/i18n.json'
import settings from '@/config/settings.json'
import { useRouter } from 'next/router'
import { SecretCodeModal } from '@/features/secret-code-vault/components'
import { useStore } from '@/stores'

// TODO: Pull from CMS, add prop for color for the list item
const Banner: React.FC = () => {
  const router = useRouter()
  const { isVaultUnlocked } = useStore()
  const { isCodeVaultModalOpen, openCodeVault, closeCodeVault } = useStore()
  const handleSecretCodeVaultButton = useCallback(
    (e: any) => {
      e.preventDefault()
      if (isVaultUnlocked) {
        router.push('/vault', undefined, { shallow: true })
        return
      }
      openCodeVault()
    },
    [router, openCodeVault, isVaultUnlocked]
  )
  return (
    <>
      <div className={'h-16 bg-black relative shadow-3xl'}>
        <div className="flex justify-center px-2 mx-auto max-w-xl xl:max-w-3xl container">
          <div
            className={
              // TODO: Marquee on very small screens https://jackwhiting.co.uk/posts/creating-a-marquee-with-tailwind/
              // TODO: Vertical align
              'bg-primary uppercase font-extrabold text-white -top-2 absolute text-sm px-4 whitespace-nowrap max-w-md  overflow-x-hidden xl:text-lg xl:max-w-lg xl:-top-4'
            }
          >
            Get Free USA Shipping On All Orders Over $
            {settings.freeShippingThreshold}
          </div>
          <div
            style={{
              WebkitOverflowScrolling: 'touch',
            }}
            className={
              // TODO: Fix spacing on desktop, switch from justify-between to space-x-?
              // TODO: Switch to flex method https://codeburst.io/how-to-create-horizontal-scrolling-containers-d8069651e9c6
              // TODO: Ensure user knows they can scroll on mobile at certain breakpoints (wiggle on first load as onboarding? save in localstorage?)
              'no-scrollbar flex justify-between uppercase text-xxs xl:text-xs w-full mt-6 space-x-1 whitespace-nowrap overflow-y-hidden overflow-x-auto'
            }
          >
            <Link href={'/vault'} prefetch={false}>
              <button
                onClick={handleSecretCodeVaultButton}
                className="bg-white rounded-full px-2 lg:px-3 py-1 inline-block uppercase"
              >
                Secret Code Vault
              </button>
            </Link>
            <Link href={'https://www.lindasbigbox.com'} prefetch={false}>
              <a
                className="bg-white rounded-full px-2 lg:px-3 py-1 inline-block"
                target={'_blank'}
              >
                Linda&apos;s Big Box
              </a>
            </Link>

            <Link href={i18n.en.other.textLindaSmsLink} prefetch={false}>
              <a className="bg-[#B4ED30] rounded-full px-2 lg:px-3 py-1 inline-block">
                Text Linda for Free Shit
              </a>
            </Link>
            <Link href={'https://onlyfans.com/kirillwashere'} prefetch={false}>
              <a
                className="bg-white rounded-full px-2 lg:px-3 py-1 inline-block"
                target={'_blank'}
              >
                Kirill&apos;s Onlyfans
              </a>
            </Link>
            <Link href={'/play/rhino-jumper'} prefetch={false}>
              <a
                className="bg-white rounded-full px-2 lg:px-3 py-1 inline-block"
                target={'_blank'}
              >
                Rhino Jumper
              </a>
            </Link>
          </div>
        </div>
      </div>
      <SecretCodeModal open={isCodeVaultModalOpen} onClose={closeCodeVault} />
    </>
  )
}

export default Banner
