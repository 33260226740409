import React from 'react'
import classNames from 'classnames'
import { RadioGroup } from '@headlessui/react'

interface IProps {
  name?: string
  value?: number
  label?: string
  options: Option[]
  onChange: (value: number) => void
}

type Option = {
  name: string
  value: number
  disabled?: boolean
}

const ProductOptionRadioGroup: React.FC<IProps> = ({
  name,
  value,
  label,
  options,
  onChange,
}) => {
  return (
    <RadioGroup value={value} onChange={onChange} name={name}>
      <RadioGroup.Label>{label}</RadioGroup.Label>
      <div className="grid grid-cols-3 gap-2">
        {options.map(({ name, value, disabled }) => (
          <RadioGroup.Option
            value={value}
            key={value}
            disabled={disabled}
            className={({ checked, active }) =>
              classNames(
                'border border-neutral-300',
                'bg-white border rounded px-3 py-2 cursor-pointer text-center font-sans-condensed text-sm',
                {
                  'cursor-pointer focus:outline-none': !disabled,
                  'cursor-not-allowed opacity-25': disabled,
                  'border-red-600': checked,
                  'border-transparent': !checked,
                }
              )
            }
          >
            <RadioGroup.Label as={'span'}>{name}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

export default ProductOptionRadioGroup
