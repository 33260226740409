import React, { useCallback, useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import { SessionProvider } from 'next-auth/react'
import { useRouter } from 'next/router'
import type { AppProps } from 'next/app'
// @ts-ignore
import NProgress from 'nprogress'
import { BareFetcher, SWRConfig } from 'swr'
import { ApolloProvider } from '@apollo/client'
import { builder } from '@builder.io/react'

import { CommerceProvider } from '@framework'
import Head from '@/components/Head'
import { storefrontClient } from '@/lib/apollo'
import { recordPageview } from '@/lib/google-analytics'

import {
  KirillsHousePartyContext,
  LiveStoryPlayerContext,
} from '@lindafinegold/kirills-house-party'
// TODO: redo this export so a stylesheet without a hash is included
import '@lindafinegold/kirills-house-party/assets/styles.8c7daac3ebe113080505.css'
import '@/assets/nprogress.css'
import '@/assets/global.css'
// import '@/assets/rhino-jumper.css'
import { useStore } from '@/stores'
import { MaintainanceModeView } from '@/features/maintainance-mode/views'
import MaintananceModeView from '@/features/maintainance-mode/views/MaintainanceModeView'

import { Layout } from '@/components'

builder.init('54e650135bc5422bab7f7c70d4e77df4')
/* Register components with Builder
 * Using "require" here because "import" is eliminated from the code in production build.
 */
require('@/features/categories/components/CategoryProductTileSet')
require('@/features/categories/components/CategoryList')

export default function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const router = useRouter()
  const { closeMainMenu, closeCart, closeCodeVault } = useStore()

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  useEffect(() => {
    const handleRouteChangeStart = () => {
      closeCart()
      closeMainMenu()
      closeCodeVault()

      NProgress.start()
    }
    const handleRouteChangeComplete = (url: string) => {
      // recordPageview(url)
      if (typeof window! === 'undefined') {
        ;(window as any).ga('send', 'pageview')
      }

      NProgress.done()
    }

    const handleRouteChangeError = (err: any, url: string) => {
      if (!err.cancelled) {
        Sentry.captureException(err)
      }

      NProgress.done()
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [closeMainMenu, closeCart, router.events, closeCodeVault])

  const fetcher: BareFetcher<any> = useCallback(
    (resource, init) => fetch(resource, init).then((res) => res.json()),
    []
  )

  return (
    // TODO: Style the error boundary fallback
    <Sentry.ErrorBoundary
      fallback={
        <Layout>
          <p>An unexpected error occured.</p>
        </Layout>
      }
    >
      <KirillsHousePartyContext.Provider
        value={{
          // TODO: Styles
          graphqlEndpoint:
            process.env.NEXT_PUBLIC_LINDAFINEGOLD_GRAPHQL_ENDPOINT ||
            'https://lindafinegold.io/graphql/',

          archiveUrl: '/kirills-house-party',
        }}
      >
        <LiveStoryPlayerContext.Provider
          value={{
            storyModeSelectorEnabled: false,
            temporaryStoryPlayerPortal:
              typeof window === 'undefined'
                ? null
                : document.querySelector(
                    '#temporary-alf-custom-portal-root-khp'
                  ),
          }}
        >
          <SessionProvider session={session}>
            {/* #34 CommerceProvider deprecated, will switch to our own SWRconfig and get rid of @vercel/commerce all together */}
            <CommerceProvider locale={router.locale}>
              <SWRConfig value={{ fetcher }}>
                <ApolloProvider client={storefrontClient}>
                  <Head />
                  {process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true' ? (
                    <MaintananceModeView />
                  ) : (
                    <Component {...pageProps} />
                  )}
                </ApolloProvider>
              </SWRConfig>
            </CommerceProvider>
          </SessionProvider>
        </LiveStoryPlayerContext.Provider>
      </KirillsHousePartyContext.Provider>
      <div
        id="temporary-alf-custom-portal-root-khp"
        className="kirills-house-party"
      />
    </Sentry.ErrorBoundary>
  )
}
