import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import fragmentMatcher from '@/types/bigcommerce/generated/fragmentMatcher.json'

const cache = new InMemoryCache({
  possibleTypes: fragmentMatcher.possibleTypes,
  typePolicies: {
    SearchProducts: {
      fields: {
        products: relayStylePagination(),
      },
    },
    Node: {
      fields: {
        products: relayStylePagination(),
      },
    },
  },
})

export default cache
