import type { Image as ImageType } from '@framework/schema'
import Image from 'next/image'
import React from 'react'

interface IProps {
  image?: ImageType
}

const CategoryListItem: React.FC<IProps> = ({ image }) => {
  return (
    <div className={'relative aspect-w-2 aspect-h-1'}>
      {image && ( // TODO: Optimize image loading
        <Image src={image.urlOriginal} alt={image.altText} layout={'fill'} />
      )}
    </div>
  )
}

export default CategoryListItem
