import React, { useCallback, useMemo } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { FiMenu, FiUser } from 'react-icons/fi'
import ALFPatchVectorLogo from '../../../public/img/alf-patch-logo.svg'

import { useStore } from '@/stores'
import { useCart } from '@/features/cart/hooks'

import CartIcon from '../CartIcon'

import Banner from './Banner'
import HousePartyButton from './HousePartyButton'

interface IProps {}

const Header: React.FC<IProps> = () => {
  const store = useStore()

  const { cart } = useCart()
  const handleMenuClick = useCallback(() => {
    store.openMainMenu()
  }, [store])

  const itemsInCart = useMemo(() => {
    let items = cart?.line_items?.physical_items.length ?? 0
    items += cart?.line_items?.digital_items.length ?? 0
    return items
  }, [cart])

  return (
    <div
      className={
        'block xl:block shadow-xl border-b-2 border-white border-solid'
      }
      role={'banner'}
    >
      <div
        className={
          'container py-4 flex items-center justify-center md:py-6 lg:max-w-xl'
        }
      >
        <div className={'flex justify-between items-center w-full space-x-5'}>
          <div className={'mt-4 w-1/3 kirills-house-party'}>
            <HousePartyButton />
          </div>
          <div className={'flex items-center justify-center'}>
            <Link href={'/'} prefetch={false}>
              <a>
                <Image
                  width={192}
                  height={121.5}
                  src={ALFPatchVectorLogo}
                  alt={'Assholes Live Forever'}
                />
              </a>
            </Link>
          </div>
          <div className={'w-1/3 flex justify-end'}>
            <div
              className={
                'grid grid-cols-2 text-3xl sm:text-4xl gap-x-7 gap-y-6 justify-center items-center'
              }
            >
              <Link href={'/help'} prefetch={false}>
                <a>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    className="w-7 h-7 bg-[red] p-[1px] rounded-full fill-[white] icon icon--full-color icon-chat"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28zm7.67-24h-16c-6.627 0-12-5.373-12-12v-.381c0-70.343 77.44-63.619 77.44-107.408 0-20.016-17.761-40.211-57.44-40.211-29.144 0-44.265 9.649-59.211 28.692-3.908 4.98-11.054 5.995-16.248 2.376l-13.134-9.15c-5.625-3.919-6.86-11.771-2.645-17.177C185.658 133.514 210.842 116 255.67 116c52.32 0 97.44 29.751 97.44 80.211 0 67.414-77.44 63.849-77.44 107.408V304c0 6.627-5.373 12-12 12zM256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8z" />
                  </svg>
                </a>
              </Link>

              <button onClick={handleMenuClick}>
                <FiMenu />
              </button>
              <Link href={'/account'} prefetch={false}>
                <a>
                  <FiUser />
                </a>
              </Link>
              <div
                className={'flex space-x-0.5 items-center relative'}
                role={'button'}
                onClick={store.openCart}
              >
                <CartIcon itemsInCart={itemsInCart} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Banner />
    </div>
  )
}

export default Header
