import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import AppStoreBadgeVectorImage from './app-store-badge.svg'
import GooglePlayBadgeVectorImage from './google-play-badge.svg'

interface IProps {}

const AppStoreBadges: React.FC<IProps> = () => {
  return (
    <div className={'flex space-x-2'}>
      <div>
        <Link
          href={'https://apps.apple.com/ky/app/linda-finegold/id1485625002'}
          prefetch={false}
        >
          <a>
            <Image
              src={AppStoreBadgeVectorImage}
              width={124}
              height={37}
              alt={'App Store'}
            />
          </a>
        </Link>
      </div>
      <div>
        <Link
          href={
            'https://play.google.com/store/apps/details?id=com.sandbox.lindafinegold'
          }
          prefetch={false}
        >
          <a>
            <Image
              src={GooglePlayBadgeVectorImage}
              width={124}
              height={37}
              alt={'Google Play'}
            />
          </a>
        </Link>
      </div>
    </div>
  )
}

export default AppStoreBadges
