import React from 'react'
import { FiShoppingBag } from 'react-icons/fi'

interface IProps {
  itemsInCart: number
}

const CartIcon: React.FC<IProps> = ({ itemsInCart }) => {
  return (
    <div className={'relative'}>
      <FiShoppingBag />
      {itemsInCart > 0 && (
        <div
          className={
            'rounded-full w-3.5 h-3.5 bg-primary absolute bottom-0 right-0 border-2 border-white border-solid'
          }
        />
      )}
    </div>
  )
}

export default CartIcon
