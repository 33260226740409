import Link from 'next/link'
import React from 'react'
import { FaReddit, FaYoutube } from 'react-icons/fa'

// TODO: Update red color
const Footer: React.FC = () => {
  return (
    <div className={'bg-[red] text-black w-full'}>
      <div
        className={
          'mx-auto py-14 text-center font-bold flex flex-col space-y-2'
        }
      >
        <div className={'mx-auto flex space-x-3'}>
          <Link
            href={'https://www.reddit.com/r/kirillwashere'}
            prefetch={false}
          >
            <a target={'_blank'}>
              <FaReddit className="text-2xl" />
            </a>
          </Link>
          <Link
            href={'https://www.youtube.com/r/kirillwashere'}
            prefetch={false}
          >
            <a target={'_blank'}>
              <FaYoutube className="text-2xl" />
            </a>
          </Link>
        </div>
        <div>
          <Link href={'/privacy-policy'} prefetch={false}>
            <a>Privacy Policy</a>
          </Link>{' '}
          -{' '}
          <Link href={'/terms-of-service'} prefetch={false}>
            <a>Terms</a>
          </Link>
        </div>
        <div>&copy; {new Date().getFullYear()} LINDA FINEGOLD</div>
      </div>
    </div>
  )
}

export default Footer
