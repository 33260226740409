import create from 'zustand'
// import lockedProductImage from '../../assets/img/locked-product.jpg'

interface IStoreState {
  themeSettings: {
    lockedProductImage: string
    lockedProductPassword: string
  }

  isCartOpen: boolean
  openCart: () => void
  closeCart: () => void

  isMainMenuOpen: boolean
  closeMainMenu: () => void
  openMainMenu: () => void

  isCodeVaultModalOpen: boolean
  closeCodeVault: () => void
  openCodeVault: () => void

  isVaultUnlocked: boolean
  // TODO: pass passwordi n here
  unlockVault: () => void
}

// TODO: Nest into slices
const useStore = create<IStoreState>((set) => ({
  themeSettings: {
    lockedProductImage: '/img/locked-product.jpg',
    lockedProductPassword: 'PASSWORD_HERE',
  },

  isCartOpen: false,
  openCart: () => {
    set({ isCartOpen: true })
  },

  closeCart: () => {
    set({ isCartOpen: false })
  },

  isMainMenuOpen: false,
  openMainMenu: () => {
    set({ isMainMenuOpen: true })
  },
  closeMainMenu: () => {
    set({ isMainMenuOpen: false })
  },

  isVaultUnlocked: true,
  /* typeof sessionStorage !== 'undefined' &&
    sessionStorage.vaultUnlocked === 'true',*/
  unlockVault: () => {
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.vaultUnlocked = 'true'
    }
    set({ isVaultUnlocked: true })
  },

  isCodeVaultModalOpen: false,
  openCodeVault: () => {
    set({ isCodeVaultModalOpen: true })
  },
  closeCodeVault: () => {
    set({ isCodeVaultModalOpen: false })
  },
}))

export default useStore
