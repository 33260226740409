const recordAddToCart = (line_item: any) => {
  // console.log('line_item', line_item)
  // const { variant_id, product_id: id, quantity } = line_item
  /*
  const dataLayer = (window as any).dataLayer || []
  // TODO: Switch to EnhancedEcommerce
  dataLayer.push({
    event: 'Product Added',
    eventCallback: function () {
      console.log('eventCallback', arguments)
    },
  })
  */
  const ga = (window as any).ga || {}
  ga('send', {
    hitType: 'event',
    eventCategory: 'EnhancedEcommerce',
    eventAction: 'Product Added',
  })
}

export default recordAddToCart
