import React from 'react'
import { LiveStoryPlayer } from '@lindafinegold/kirills-house-party'

interface IProps {
  label?: string
}

// TODO: Completely redo this so LiveStoryPlayer uses a global store, will have to update the KHP package module exports
// to allow for this composition ability
const HousePartyButton: React.FC<IProps> = ({
  label = "Kirill's House Party",
}) => {
  return (
    <div className="inline-flex flex-col text-center space-y-[16px] items-center">
      <LiveStoryPlayer />
      <div
        className={
          'uppercase text-[9px] sm:text-[10px] tracking-tight text-gray-900 leading-none font-sans-condensed font-black'
        }
      >
        {label}
      </div>
    </div>
  )
}

export default HousePartyButton
