import React from 'react'

import ALFInstagramVectorLogo from './alf-instagram-logo.svg'
import KirillInstagramVectorLogo from './kirill-instagram-logo.svg'
import LindafinegoldInstagramVectorLogo from './lindafinegold-instagram-logo.svg'

import InstagramSection from './InstagramSection'

const InstagramTiles: React.FC = () => {
  return (
    <div className={'flex space-x-4'}>
      <InstagramSection
        href={'https://instagram.com/kirillagain'}
        image={KirillInstagramVectorLogo}
        label={"Kirill's Instagram"}
      />
      <InstagramSection
        href={'https://instagram.com/lindafinegold'}
        image={LindafinegoldInstagramVectorLogo}
        label={"Linda's Instagram"}
      />
      <InstagramSection
        href={'https://instagram.com/assholesliveforever'}
        image={ALFInstagramVectorLogo}
        label={'ALF Instagram'}
      />
    </div>
  )
}

export default InstagramTiles
