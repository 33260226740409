import axios from 'axios'
import { useCallback } from 'react'
import useCart from './use-cart'

const useUpdateItem = () => {
  const { mutate } = useCart()
  const updateItem = useCallback(
    ({ item_id, line_item }: { item_id: string; line_item: any }) => {
      return mutate(async () => {
        const { data: cart } = await axios.put(
          `/api/cart/items/${item_id}`,
          line_item
        )
        return cart
      })
    },
    [mutate]
  )
  return updateItem
}
export default useUpdateItem
