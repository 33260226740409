import { useRouter } from 'next/router'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FiSearch } from 'react-icons/fi'

interface IProps {
  onSubmit: (data: any) => void
}

const SearchForm: React.FC<IProps> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={'relative'}>
        <input
          disabled={isSubmitting}
          {...register('q', { required: true })}
          placeholder={'LOOKING FOR SOMETHING, STUPID?'}
          className={'input rounded-full py-3 px-8 placeholder:font-thin'}
        />
        <button
          className={
            'absolute inset-y-0 right-0 cursor-pointer pr-3 flex items-center'
          }
        >
          <FiSearch className={'text-lg'} />
        </button>
      </div>
    </form>
  )
}

export default SearchForm
