import axios from 'axios'
import { useCallback } from 'react'
import useCart from './use-cart'

const useRemoveCoupon = () => {
  const { mutate } = useCart()
  const removeCoupon = useCallback(
    async (code: string) => {
      const { data: cart } = await axios.delete(
        `/api/cart/coupons?code=${code}`
      )
      return mutate(cart)
    },
    [mutate]
  )
  return removeCoupon
}
export default useRemoveCoupon
