import React, { useCallback, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'

import type {
  ProductOptionConnection,
  VariantConnection,
  MultipleChoiceOption,
} from '@/types/bigcommerce/generated/graphql'

import { useAddItem } from '@/features/cart/hooks'
import { AddToCartFormData } from '../types'

import ProductOptionRadioGroup from './ProductOptionRadioGroup'

interface IProps {
  productId: number
  options?: ProductOptionConnection
  variants?: VariantConnection
  unlimitedInventory?: boolean
  onSuccess?: () => void
}

const RadioGroupCartAddForm: React.FC<IProps> = ({
  onSuccess,
  productId,
  options,
  unlimitedInventory,
  variants,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<AddToCartFormData>()
  const { addLineItem } = useAddItem({
    onSuccess,
  })
  const onSubmit = handleSubmit(async (data) => {
    return addLineItem(data)
  })

  const radioGroupOptions = variants!.edges!.map((variant) => ({
    disabled: !unlimitedInventory && !variant?.node.inventory?.isInStock,
    value: variant?.node.entityId!,
    name: variant?.node.productOptions?.edges?.map(
      (
        productOption // TODO: factor out into a util function
      ) =>
        (productOption?.node as MultipleChoiceOption).values?.edges?.map(
          (value) => value?.node.label
        )[0]
    )[0]!,
  }))

  return (
    <form
      method={'post'}
      action={'/api/cart'}
      encType={'multipart/form-data'}
      onSubmit={onSubmit}
    >
      <input type={'hidden'} {...register('action', { value: 'add' })} />
      <input type={'hidden'} {...register('quantity', { value: 1 })} />
      <input
        type={'hidden'}
        {...register('product_id', { value: productId })}
      />
      <div className={'pt-4 pb-8 flex flex-col space-y-4'}>
        <Controller
          name={'variant_id'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ProductOptionRadioGroup options={radioGroupOptions} {...field} />
          )}
        />

        <button
          className={classNames('button button--primary', {
            'button--loading': isSubmitting,
          })}
          disabled={isSubmitting}
        >
          Add to Cart
        </button>
      </div>
    </form>
  )
}

export default RadioGroupCartAddForm
