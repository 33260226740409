import React, { useCallback } from 'react'
import type { Product } from '@/types/bigcommerce/generated/graphql'
import BottomDrawer from '@/components/BottomDrawer'
import RadioGroupCartAddForm from '../RadioGroupCartAddForm'
import { useStore } from '@/stores'
import { useProduct } from '../../hooks'

interface IProps {
  open: boolean
  title: string
  product: Product
  onClose: () => void
}

const AddToCartFormDrawer: React.FC<IProps> = ({
  open,
  title,
  product,
  onClose,
}) => {
  const { unlimitedInventory } = useProduct(product)
  return (
    <BottomDrawer title={title} open={open} onClose={onClose}>
      <RadioGroupCartAddForm
        unlimitedInventory={unlimitedInventory}
        productId={product.entityId}
        onSuccess={onClose}
        variants={product.variants}
        options={product.productOptions}
      />
    </BottomDrawer>
  )
}

export default AddToCartFormDrawer
