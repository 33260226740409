import React, { useCallback, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Dialog } from '@headlessui/react'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useStore } from '@/stores'

type Props = {
  open: boolean
  onClose: () => void
}

const schema = object().shape({
  password: string()
    .required('Required')
    .min(8, 'Must be 8 characters long')
    .max(8, 'Must be 8 characters long'),
})

// TODO: Match 1-1 with old design
const SecretCodeModal = ({ open, onClose }: Props) => {
  const router = useRouter()
  const { unlockVault } = useStore()
  let initialFocusRef = useRef(null)
  const { handleSubmit, register, watch } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = useCallback(
    ({ password }: any) => {
      if (password.toUpperCase() === 'BANGBANG') {
        unlockVault()
        router.push('/vault')
        return
      }

      alert('Incorrect password')
    },
    [router, unlockVault]
  )

  const passwordLength = watch('password')?.length

  return (
    <Dialog
      className="absolute flex items-center justify-center top-0 left-0 w-full h-screen z-50 bg-black bg-opacity-80"
      open={open}
      initialFocus={initialFocusRef}
      onClose={onClose}
    >
      <Dialog.Panel className="w-[95%] max-w-[500px] flex flex-col justify-between">
        <div className="bg-[#fff24a] px-7 md:px-10 py-3 md:py-7 text-center font-black font-archivo text-lg leading-4">
          <Dialog.Title className="font-black text-xs leading-4 md:text-base md:leading-5">
            IF YOU BOUGHT THE VAULT BOX, CHECK YOUR EMAIL. WE HAVE SENT YOU THE
            PASSWORD. MAKE SURE YOU CHECK IN SPAM.
          </Dialog.Title>
          <form onSubmit={handleSubmit(onSubmit)} className="my-3 md:my-4">
            <p className="text-[#F00] font-black text-xs md:text-sm leading-4">
              ENTER PASSWORD BELOW:
            </p>
            <input
              type="text"
              {...register('password', { required: true })}
              maxLength={8}
              placeholder="XXXXXXXX"
              className="bg-white my-2 md:my-2.5 p-1.5 md:p-2 border border-[#F00] w-full placeholder:text-gray-500 placeholder:font-light text-xs md:text-lg font-medium leading-4 focus:border-[#F00] focus:ring-0 uppercase"
            />
            <div className="flex items-center justify-center">
              <button
                type={'button'}
                onClick={onClose}
                className="text-base leading-[18px] py-2 px-6 font-black text-center border border-black bg-white text-[#F00] mr-0.5 cursor-pointer"
              >
                Cancel
              </button>
              <button
                className={`transition-all text-base leading-[18px] py-2 px-6 font-black text-center border border-black ml-0.5 ${
                  passwordLength !== 8
                    ? 'bg-[#CCC] text-[#333]'
                    : 'bg-white cursor-pointer text-[#F00]'
                }`}
                disabled={passwordLength !== 8}
              >
                Submit
              </button>
            </div>
          </form>
          <Dialog.Description className="font-black text-xs leading-4 md:text-base md:leading-[18px]">
            IF YOU WERE A FUCKING MORON & DIDN’T BUY THE VAULT BOX, CLICK BELOW
            TO BE TAKEN TO THE PAGE WHERE YOU CAN BUY IT. YOU WILL BE EMAILED
            THE PASSWORD WITHIN 5 MINUTES AFTER PURCHASING.
          </Dialog.Description>
        </div>

        <Link
          href={'/products/secret-code-vault-mystery-box/'}
          prefetch={false}
        >
          <a className={'bg-primary'}>
            <Image
              src="https://cdn11.bigcommerce.com/s-hyaye2htx7/images/stencil/500w/image-manager/code-vault-modal-image.jpg"
              alt={'Code Vault'}
              unoptimized
              width={500}
              height={373}
              objectFit={'cover'}
            />
          </a>
        </Link>
      </Dialog.Panel>
    </Dialog>
  )
}

export default SecretCodeModal
