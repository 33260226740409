import { useMemo } from 'react'
import i18n from '@/config/i18n.json'
import { AlertBanner } from '@/components'

// TODO: Switch to SVG like the SoldOutAlertBanner
const MobileAlertBanner: React.FC = () => {
  const productLanguage = useMemo(() => i18n.en.product, [])
  return (
    <AlertBanner>
      <span className={'select-auto cursor-auto'}>
        {productLanguage.alertBanner.text}
      </span>
    </AlertBanner>
  )
}

export default MobileAlertBanner
