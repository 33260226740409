import React from 'react'
import classNames from 'classnames'

interface IProps {
  top: React.ReactNode
  bottom?: React.ReactNode
}

const ProductTileLayout: React.FC<IProps> = ({ top, bottom }) => {
  return (
    <div className={'space-y-9'}>
      <div className={classNames('aspect-w-3 aspect-h-4 bg-neutral-200')}>
        {top}
      </div>
      {bottom && (
        <div className={classNames('w-[87.5%] mx-auto')}>{bottom}</div>
      )}
    </div>
  )
}

export default ProductTileLayout
