import useSWR from 'swr'

const useCart = () => {
  const { data, error, mutate } = useSWR('/api/cart')
  return {
    cart: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

export default useCart
