import React, { useCallback, useMemo, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useStore } from '@/stores'
import type { Product } from '@/types/bigcommerce/generated/graphql'

import { useProduct } from '../../hooks'
import RadioGroupCartAddForm from '../RadioGroupCartAddForm'
import ProductDetails from '../ProductDetails'
import SoldOutAlertBanner from '../SoldOutAlertBanner'
import ProductTileAlertBanner from '../ProductTileAlertBanner'

import ProductTileLayout from './ProductTileLayout'
import ActionButton from './ActionButton'
import AddToCartFormDrawer from './AddToCartFormDrawer'
import ActionButtonCartAddForm from './ActionButtonCartAddForm'

interface IProps {
  product: Product
}

const ProductTile: React.FC<IProps> = ({ product }) => {
  const {
    soldOut,
    path,
    images,
    title,
    subtitle,
    isPasswordProtected,
    originalPriceIfOnSale,
    unlimitedInventory,
  } = useProduct(product)

  const router = useRouter()
  const { themeSettings } = useStore()
  const [addToCartViewOpen, setAddToCartViewOpen] = useState(false)
  const [isLocked, setIsLocked] = useState(isPasswordProtected)

  const closeAddToCartView = useCallback(() => {
    setAddToCartViewOpen(false)
  }, [])

  const hasVariants = useMemo(() => {
    return product.variants?.edges && product.variants.edges.length > 1
  }, [product.variants.edges])

  const handleHasVariantActionButtonClick = useCallback(async () => {
    setAddToCartViewOpen(true)
  }, [])

  const handleUnlockActionButtonClick = useCallback(() => {
    router.push(path)
    return
  }, [path, router])

  return (
    <>
      <ProductTileLayout
        top={
          <div>
            <Link href={path} prefetch={false}>
              <a>
                <Image
                  // TODO: Use BigCommerce (or cloudinary) servers to manipulate sizes and URLs + optimize?
                  src={
                    isLocked ? themeSettings.lockedProductImage : images[0]?.url
                  }
                  layout={'fill'}
                  alt={title}
                  objectFit={'cover'}
                />
              </a>
            </Link>
            {(isLocked || !soldOut) && (
              <>
                {addToCartViewOpen && (
                  <div
                    className={
                      'absolute inset-x-5 bottom-5 bg-white px-4 pt-4 z-20 border border-neutral-800 hidden sm:block'
                    }
                  >
                    <div className={'flex justify-between'}>
                      <div
                        className={'font-sans-condensed text-sm font-medium'}
                      >
                        Select Size
                      </div>
                      <button onClick={closeAddToCartView}>
                        <IoMdClose />
                      </button>
                    </div>
                    <div>
                      <RadioGroupCartAddForm
                        unlimitedInventory={unlimitedInventory}
                        onSuccess={closeAddToCartView}
                        productId={product.entityId}
                        options={product.productOptions}
                        variants={product.variants}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={classNames('absolute bottom-5 right-6', {
                    invisible: false,
                  })}
                >
                  {isLocked && (
                    <ActionButton
                      onClick={handleUnlockActionButtonClick}
                      mode={'unlock'}
                    />
                  )}
                  {!isLocked && hasVariants && (
                    <ActionButton
                      onClick={handleHasVariantActionButtonClick}
                      mode={'add-to-cart'}
                    />
                  )}
                  {!isLocked && !hasVariants && (
                    <ActionButtonCartAddForm productId={product.entityId} />
                  )}
                </div>
              </>
            )}
          </div>
        }
        bottom={
          <div className={'relative'}>
            <div
              className={classNames(
                'absolute w-full select-none cursor-default',
                {
                  'bottom-12 sm:bottom-14': !isLocked && soldOut,
                  '-top-12': isLocked || !soldOut,
                }
              )}
            >
              {!isLocked && soldOut ? (
                <SoldOutAlertBanner />
              ) : (
                <ProductTileAlertBanner />
              )}
            </div>
            <ProductDetails
              title={isLocked ? '** Locked Product **' : title}
              subtitle={isLocked ? '' : subtitle}
              path={path}
              price={isLocked ? undefined : product.prices?.price.value}
              originalPrice={isLocked ? undefined : originalPriceIfOnSale}
            />
          </div>
        }
      />
      {addToCartViewOpen &&
        !isLocked && ( // TODO: Hide on desktop, use a resizeobserver?
          <AddToCartFormDrawer
            title={'Select Size'}
            open
            onClose={closeAddToCartView}
            product={product}
          />
        )}
    </>
  )
}

export default ProductTile
