import React from 'react'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'

interface IProps {
  image: string | StaticImageData
  label: string
  href: string
}
const InstagramSection: React.FC<IProps> = ({ image, label, href }) => {
  return (
    <Link href={href} prefetch={false}>
      <a className={'space-y-2 text-center'} target={'_blank'}>
        <div className={'overflow-hidden rounded-full mx-auto relative'}>
          <Image
            src={image}
            layout={'responsive'}
            width={24}
            height={24}
            alt={''}
          />
        </div>
        <div
          className={
            'uppercase text-primary font-bold leading-none w-20 mx-auto text-center text-xs'
          }
        >
          {label}
        </div>
      </a>
    </Link>
  )
}

export default InstagramSection
