const logoutOfKustomer = () => {
  const Kustomer = (window as any).Kustomer
  Kustomer.logout((response: any, error: any) => {
    if (error) {
      console.error(error)
    }
    console.log('logged out from Kustomer', response)
  })
}

export default logoutOfKustomer
