import React from 'react'
import { Dialog } from '@headlessui/react'
import { IoIosCloseCircle } from 'react-icons/io'

interface IProps {
  open: boolean
  title: string
  children?: React.ReactNode
  onClose: () => void
}

// TODO: Transitions and animations
const BottomDrawer: React.FC<IProps> = ({ open, title, children, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} className={'relative z-50'}>
      <div
        className={'bg-black/80 backdrop-blur-sm fixed inset-0'}
        // Do not use Dialog.Panel to auto close the backdrop because for some reason
        // on mobile (touch events) it doesn't stop propagation on the backdrop
        // Should probably create an issue on headlessui. 5/31/22 - PF
        onClick={onClose}
        aria-hidden={'true'}
      />
      <div className={'fixed bottom-0 inset-x-0'}>
        <div className={'bg-white w-full rounded-t-xl px-6'}>
          <div>
            <div className="flex w-full items-center">
              <div className={'mx-auto'}>
                <Dialog.Title
                  className={
                    'text-center py-4 font-sans-condensed font-semibold uppercase'
                  }
                >
                  {title}
                </Dialog.Title>
              </div>

              <div>
                <button onClick={onClose}>
                  <IoIosCloseCircle
                    color={'#909090'}
                    className={'text-xl'}
                    title={'Close'}
                  />
                </button>
              </div>
            </div>
            <hr />
          </div>
          <div>{children}</div>
        </div>
      </div>
    </Dialog>
  )
}

export default BottomDrawer
