import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import KustomerVectorLogo from './kustomer-logo.svg'
import PovertyClubVectorLogo from './poverty-club-logo.svg'

interface IProps {}

const ThirdPartyWidgets: React.FC<IProps> = () => {
  return (
    <div className={'flex space-x-4'}>
      <Link href={'/help?open_chat=true'} prefetch={false}>
        <a className="flex items-center space-x-2">
          <div className={'w-[60px] uppercase leading-4 text-right'}>
            <strong className={'text-white'}>Live Chat</strong>
            <div className={'text-xs text-[#909090]'}>
              With a customer support expert
            </div>
          </div>
          <div className={'relative'}>
            <Image
              src={KustomerVectorLogo}
              width={96 * 0.8}
              height={125 * 0.8}
              alt={'Kustomer'}
            />
          </div>
        </a>
      </Link>

      <a href="#smile-home" className="flex items-center space-x-2">
        <div className={'relative'}>
          <Image
            src={PovertyClubVectorLogo}
            width={105.5 * 0.8}
            height={131 * 0.8}
            alt={'Poverty Club'}
          />
        </div>
        <div className={'w-[73px] uppercase leading-4'}>
          <strong className={'text-white'}>Poverty Club</strong>
          <div className={'text-xs text-[#909090]'}>
            A rewards program for poor people
          </div>
        </div>
      </a>
    </div>
  )
}

export default ThirdPartyWidgets
